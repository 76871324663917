import styles from "./Header.module.scss";
import MenuOuvrant from "../MenuOuvrant/MenuOuvrant";
import React, { useState } from "react";

function Header (){
    const [menuVisibility, setMenuVisibility] = useState("none");
    const [backgroundMenu, setBackgroundMenu] = useState("")
    
    function showMenu (){
        setMenuVisibility("flex");
    }
    
    function resetMenu (value){
        setMenuVisibility(value);
    }

    window.addEventListener("scroll", ()=>{
        if(window.scrollY > 30){
            setBackgroundMenu("headerBackground");
        }
        else{
            setBackgroundMenu("");
        }
    })

    return (
        <>
            <div className={`margeResponsive ${styles.Container} ${backgroundMenu}`}>
                {/* <h2 className={styles.Logo}><a href="/kmaraab">CAMARA</a></h2> */}
                <h2 className={styles.Logo}><a href="/">choose<span>ws</span></a></h2>
                <div className={styles.LinkMenu}>
                    {/* <p><i className="fa-solid fa-phone-flip"></i></p> */}
                    <p><a href="tel:+33745649205"> <i className="fa-solid fa-phone-flip"></i> <span>(033) 745 649 205</span></a></p>
                    <div onClick={showMenu} className={styles.MenuBurgerIcone}>
                        <p></p>
                        <p></p>
                        <p></p>
                    </div>
                </div>
            </div>
            <MenuOuvrant resetMenu={resetMenu} menuStat={menuVisibility} />
        </>
    )
}


export default Header;