import styles from "./MesCompetences.module.scss";
// import CV from "../../assets/doc/CV Aboubacar Camara.pdf";
import OneSkill from "./OneSkill/OneSkill";

function MesCompetences (){

    return (
        <div id="skills" className={styles.container}>
            <h2>Mes Compétences & Expertises</h2>
            <div className={styles.allSkills}>
                <OneSkill title="Création d’application web et mobile" percent="100"/>
                <OneSkill title="Création de boutique e-commerce" percent="100"/>
                <OneSkill title="Conception / intégration / versionning d’API Rest, GraphQL" percent="100"/>
                <OneSkill title="Création de dashboard / back office de gestion" percent="100"/>
                <OneSkill title="Web scrapping" percent="100"/>
                <OneSkill title="Création de bot automatisé" percent="100"/>
                <OneSkill title="ReactJs / React Native" percent="90"/>
                <OneSkill title="NextJs" percent="70"/>
                <OneSkill title="Javascript / Typescript" percent="90"/>
                <OneSkill title="NodeJs / Express" percent="90"/>
                <OneSkill title="git et GitHub" percent="70"/>
                <OneSkill title="MongoDB(noSQL)" percent="80"/>
                <OneSkill title="Puppeteer" percent="80"/>
                <OneSkill title="Figma / Adobe XD / Canva" percent="60"/>
                <OneSkill title="Trello / Monday / Google Agenda" percent="80"/>
                <OneSkill title="WordPress & Shopify" percent="80"/>
            </div>

            {/* <div className={styles.CVcontainer}>
                <a href={CV} download><div className={styles.downloadCV}>Télécharger mon CV</div></a>
            </div> */}
        </div>
    )
}


export default MesCompetences;