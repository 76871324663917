import styles from "./MonPortfolio.module.scss";
import OneProject from "../OneProject/OneProject";

import DemoFullAnalytics from "../../assets/video/Full analytics.mp4";
import DemoNeedForWrap from "../../assets/video/NeedForWrap.mp4";
import DemoDetybel from "../../assets/video/Detybel.mp4";
import DemoOhmyfood from "../../assets/video/Ohmyfood.mp4";
import DemoKasa from "../../assets/video/Kasa.mp4";
import DemoLHCommunication from "../../assets/video/LH-Communication.mp4";

import FullAnalytics from "../../assets/images/fullanalytics.png";
import NeedForWrap from "../../assets/images/needforwrap.png";
import Detybel from "../../assets/images/detybel.png";
import Ohmyfood from "../../assets/images/ohmyfood.png";
import Kasa from "../../assets/images/kasa.png";
import LHCommunication from "../../assets/images/lhcommunication.png";

function MonPortfolio (){

    return (
        <div id="portfolio" className={`margeResponsive ${styles.container}`}>
            <h2>Mon portfolio</h2>
            <p className={styles.sousTitre}>Quelques-uns de mes projets</p>
            <p className={styles.description}>Cliquez sur un projet pour en savoir plus (techno, date de création...) ou voir une démo en vidéo.</p>
            <div className={styles.moreProjectContainer}>
                <a href='https://vimeo.com/zasniws' target="_blank"><div className={styles.btnSeeMore}>Voir mes récentes réalisations</div></a>
            </div>
            <div className={styles.AllProjects}>
                <OneProject 
                    imagePres={FullAnalytics}
                    videoDemo={DemoFullAnalytics}
                    nameProject= "Full Analytics"
                    pres1="Full Analytics est un tout nouvel outil révolutionnaire pour les professionnels du marketing de l'influence"
                    presentation="Full Analytics est un tout nouvel outil révolutionnaire pour les professionnels du marketing de l'influence. Full Analytics vous permet d'analyser en profondeur le profil de vos influenceurs préférés sur les réseaux sociaux, en fournissant des informations précieuses pour vous aider à prendre des décisions éclairées et à optimiser vos stratégies de marketing d'influence."
                    technologies={["React", "React Router", "SASS", "JS-Cookies", "NodeJS", "bcrypt", "cors","express", "jsonwebtoken", "mongoose", "MongoDB", "nodemailer", "nodemon", "puppeteer"]}
                    dateCrea="En cours de dévélopemment"
                />
                <OneProject 
                    imagePres={NeedForWrap}
                    videoDemo={DemoNeedForWrap}
                    nameProject= "Need For Wrap"
                    pres1="Need For Wrap est une élégante boutique d'habillement automobile."
                    presentation="Need For Wrap est une élégante boutique d'habillement automobile, située au cœur de Paris, offrant une sélection exclusive de produits. J'ai eu le privilège de réaliser leur site en seulement trois semaines, démontrant ainsi mon efficacité et mon expertise."
                    technologies={["WordPress", "Elementor", "CodeSnippets", "WordFence", "WP Case", "..."]}
                    dateCrea="Avril, 2023"
                />
                <OneProject 
                    imagePres={Detybel}
                    videoDemo={DemoDetybel}
                    nameProject= "Detybel Shop"
                    pres1="Detybel est une boutique de vente dans le domaine des perruques pour femmes."
                    presentation="Detybel est une boutique de vente dans le domaine des perruques pour femmes, proposant une collection raffinée et variée. En tant que développeur web au sein de l'Agence Marketing Bordeaux, j'ai créé le site pour la cliente en utilisant un template de base, lui donnant ainsi une allure unique et personnalisée."
                    technologies={["WordPress", "WooCommerce", "Elementor", "Stripe", "PayPal", "CodeSnippets", "WordFence", "WP Case", "..."]}
                    dateCrea="Juin, 2023"
                />
                <OneProject 
                    imagePres={Ohmyfood}
                    videoDemo={DemoOhmyfood}
                    nameProject= "Oh my Food"
                    pres1="Oh my Food est un site soigneusement conçue, réunissant les cartes des restaurants parisiens."
                    presentation="Oh my Food est un site soigneusement conçue, réunissant les cartes des restaurants parisiens, permettant ainsi à l'utilisateur de réserver facilement dans son établissement préféré."
                    technologies={["HTML & CSS", "SASS & BEM", "JavaScript"]}
                    dateCrea="Mai, 2022"
                />
                <OneProject 
                    imagePres={Kasa}
                    videoDemo={DemoKasa}
                    nameProject= "Kasa"
                    pres1="Kasa est un site qui offre aux utilisateurs la possibilité de publier des annonces de location de maisons"
                    presentation="Kasa est un site qui offre aux utilisateurs la possibilité de publier des annonces de location de maisons."
                    technologies={["HTML & CSS", "SASS", "JavaScript", "React", "React Router"]}
                    dateCrea="Juillet, 2022"
                />
                <OneProject 
                    imagePres={LHCommunication}
                    videoDemo={DemoLHCommunication}
                    nameProject= "LH Communication"
                    pres1="LH Communication présente le site internet de cette agence du même nom."
                    presentation="LH Communication présente le site internet de cette agence du même nom, dont j'ai été invité à réaliser une refonte complète pendant mon expérience en tant que développeur web en alternance. Depuis, le site a été légèrement ajusté."
                    technologies={["WordPress", "Elementor", "CodeSnippets", "WordFence", "WP Case", "..."]}
                    dateCrea="Avril, 2022"
                />
            </div>
            
        </div>
    )
}


export default MonPortfolio;