import React from 'react';

function SmoothScrollLink({ targetId, children }) {
  const handleClick = (e) => {
    e.preventDefault();

    const targetElement = document.querySelector(targetId);
    const targetPosition = targetElement.offsetTop;

    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth'
    });
  };

  return (
    <a href={targetId} onClick={handleClick}>
      {children}
    </a>
  );
}

export default SmoothScrollLink;
